import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deleteApi, editApi, getApiList } from '@/api/apiList';
import { getApiGroupList } from '@/api/apiGroup';
export default {
  name: "ManagerApis",
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {},
      page: 1,
      total: 0,
      requestMethodMap: [],
      apiGroupMap: [],
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      editIndex: '',
      newForm: {
        id: 0,
        name: '',
        request_method: 1,
        path: '',
        ag_id: '',
        description: "",
        remark: ""
      },
      rules: {
        name: [{
          required: true,
          message: '接口名称 必填'
        }],
        request_method: [{
          required: true,
          message: '请求方式 必填'
        }],
        path: [{
          required: true,
          message: '路由 必填'
        }],
        ag_id: [{
          required: true,
          message: '分类 必填'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.requestData(1);
    getApiGroupList({
      is_only_group: true
    }).then(function (res) {
      _this.apiGroupMap = res.data.data;
    });
  },
  methods: {
    requestData: function requestData(page) {
      var _this2 = this;

      if (page !== null) {
        this.page = page;
      }

      this.loading = true;
      getApiList(_objectSpread(_objectSpread({}, this.queryParams), {}, {
        page: this.page
      })).then(function (res) {
        _this2.loading = false;
        _this2.data = res.data.data;
        _this2.total = res.data.total_count;

        if (_this2.requestMethodMap.length < 1) {
          _this2.requestMethodMap = res.data.request_method;
        }
      }).catch(function (e) {
        _this2.$message.error(e.message);
      });
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteApi({
          id: row.id
        }).then(function () {
          _this3.$message.success('删除成功');

          _this3.data.splice(index, 1);
        }).catch(function (e) {
          _this3.$message.error(e.message);
        });
      }).catch(function () {
        _this3.$message.info('已取消删除');
      });
    },
    handleEdit: function handleEdit(index, row) {
      var _this4 = this;

      this.editIndex = '';

      if (row) {
        Object.keys(row).forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(_this4.newForm, key) && row[key] != null) _this4.newForm[key] = row[key];
        });
        this.editIndex = index;
      } else {
        this.newForm = {
          id: 0,
          name: '',
          request_method: 1,
          path: '',
          ag_id: '',
          description: "",
          remark: ""
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this5 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this5.newForm);

          editApi(form).then(function (res) {
            _this5.submitting = false;

            _this5.$message.success('编辑成功');

            _this5.dialogNewVisible = false;

            if (_this5.editIndex === "") {
              _this5.data.unshift(res.data);
            } else {
              var arr = _this5.data.slice();

              arr[_this5.editIndex] = res.data;
              _this5.data = arr;
            }
          }).catch(function (e) {
            _this5.submitting = false;

            _this5.$message.error(e.message);
          });
        } else {
          _this5.submitting = false;
        }
      });
    }
  }
};